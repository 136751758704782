import {
  GIRL_NAME,
  GIRL_FATHER_NAME,
  GIRL_MOTHER_NAME,
  GIRL_NAME_SHORT,
  // boy section
  BOY_FATHER_NAME,
  BOY_NAME,
  BOY_MOTHER_NAME,
  BOY_NAME_SHORT,
  // another
  BROADCAST_WEDDING_DAY,
  BROADCAST_WEDDING_LOCATION,
  IS_BOY_FIRST,
} from '@/constants';
import { customText } from './customText';
//import { InvertText } from './InvertText';
import { MESSAGE } from './templateText';


const FINAL_BROADCAST = {
  ...MESSAGE,
  ...customText
}

function getBroadcastText({ link, guestName, id, time }) {
  const lang = id.includes('EN_') ? 'en' : 'id';

  const result = FINAL_BROADCAST[id]
    .replace('{{GUEST_NAME}}', guestName)
    .replace('{{LINK}}', link)
    .replace('{{BOY_NAME}}', IS_BOY_FIRST ? BOY_NAME : GIRL_NAME)
    .replace('{{GIRL_NAME}}', IS_BOY_FIRST ? GIRL_NAME : BOY_NAME)
    .replace('{{GIRL_FATHER_NAME_ID}}', IS_BOY_FIRST ? "_Putri dari Bapak " + GIRL_FATHER_NAME : "_Putra dari Bapak " + BOY_FATHER_NAME)
    .replace('{{GIRL_FATHER_NAME_EN}}', IS_BOY_FIRST ? "_The daughter of Mr. " + GIRL_FATHER_NAME : "_The son of Mr. " + BOY_FATHER_NAME)
    .replace('{{GIRL_FATHER_NAME}}', IS_BOY_FIRST ? GIRL_FATHER_NAME : BOY_FATHER_NAME)
    .replace('{{GIRL_MOTHER_NAME}}', IS_BOY_FIRST ? GIRL_MOTHER_NAME : BOY_MOTHER_NAME)
    .replace('{{BOY_FATHER_NAME_ID}}', IS_BOY_FIRST ? "_Putra dari Bapak " +BOY_FATHER_NAME : "_Putri dari Bapak " + GIRL_FATHER_NAME)
    .replace('{{BOY_FATHER_NAME_EN}}', IS_BOY_FIRST ? "_The son of Mr. " +BOY_FATHER_NAME : "_The daughter of Mr. " + GIRL_FATHER_NAME)
    .replace('{{BOY_FATHER_NAME}}', IS_BOY_FIRST ? BOY_FATHER_NAME : GIRL_FATHER_NAME)
    .replace('{{BOY_MOTHER_NAME}}', IS_BOY_FIRST ? BOY_MOTHER_NAME : GIRL_MOTHER_NAME)
    .replace('{{GIRL_NAME_SHORT}}', IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT)
    .replace('{{BOY_NAME_SHORT}}', IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT)
    .replace('{{BROADCAST_WEDDING_LOCATION}}', BROADCAST_WEDDING_LOCATION)
    .replace('{{TIME}}', time)
    .replace('{{BROADCAST_WEDDING_DAY}}', BROADCAST_WEDDING_DAY[lang]);
    
  return result;
}

export default getBroadcastText;