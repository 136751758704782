import React, { useEffect, useState } from 'react';
import { node } from 'prop-types';
import {
  Box,
  FormLabel,
  Input,
  Text,
  FormControl,
  Button,
  FormErrorMessage,
  Spinner,
  Center,
  Flex,
  Image,
  Link,
} from '@chakra-ui/react';
import useIsDesktopView from '@/hooks/useIsDesktopView';
import { THE_BRIDE, PASSWORD_LINK_GENERATOR, INVITATO_URL } from '@/constants';
import { BG_COVER, LOGO_INVITATO } from '@/constants/assets';
import { INPUT_PROPS } from '../types';
import { getItem, setItem } from '@/helpers/localstorage';

import { styAuthWrapper } from '../styles';

const LS_KEY = 'ath-vlu';

/**
 * @function Auth
 * @param {*} props
 */
function Auth({ children }) {
  const { isDesktopView } = useIsDesktopView();

  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validatePassword = () => {
    if (value === PASSWORD_LINK_GENERATOR) {
      setIsAuth(true);
      setItem(LS_KEY, value);
      return;
    }

    setErrorMessage('Password tidak sesuai. Silahkan coba lagi.');
  };

  useEffect(() => {
    const prevPassword = getItem(LS_KEY);
    if (prevPassword === PASSWORD_LINK_GENERATOR) {
      setIsAuth(true);
    }

    setIsLoading(false);
  }, []);

  if (isLoading)
    return (
      <Box width="100%" padding="42px 0">
        <Center>
          <Spinner margin="0 auto" height="40px" width="40px" />
        </Center>
        <Text fontFamily="Newsreader" textAlign="center" marginTop="16px">
          Validasi akun user..
        </Text>
      </Box>
    );

  if (isAuth) return children;

  return (
    <Box className={styAuthWrapper} data-n-desktop={isDesktopView} fontFamily="Newsreader">
      {isDesktopView && (
        <Box
          height={'100vh'}
          background={`linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${BG_COVER})`}
          bgSize={'cover'}
          color={'white'}
          paddingX={'2rem'}
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
        >
          <Text fontSize={'6xl'} fontStyle={'italic'} fontWeight={'300'}>
            Hello,
          </Text>
          <Text marginTop="-12px" fontSize={'3xl'}>
            {THE_BRIDE}
          </Text>
          <Text fontSize={'xl'} fontStyle={'italic'}>
            One more step to
            <br /> share your love story!
          </Text>
        </Box>
      )}

      <Box className="box__wrapper" data-n-desktop={isDesktopView}>
        <Box className="box__outer-container" data-n-desktop={isDesktopView}>
          <Box margin="0 auto" className="box__border-container" data-n-desktop={isDesktopView}>
            <>
              <Center>
                <Text marginTop="16px" color="#1A202C" fontStyle={'italic'} fontSize="xl">
                  Generator Link
                </Text>
              </Center>
              <Center>
                <Text color="#1A202C" fontSize="3xl">
                  {THE_BRIDE}
                </Text>
              </Center>

              <FormControl isInvalid={errorMessage}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  gap="2"
                >
                  <Flex
                    alignItems="start"
                    flexDirection="column"
                    width="95%"
                    maxWidth="400px"
                    py="18px"
                  >
                    <FormLabel>Password:</FormLabel>
                    <Input
                      {...INPUT_PROPS}
                      size="md"
                      borderRadius="0"
                      maxWidth="400px"
                      type="password"
                      placeholder="..."
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          validatePassword();
                        }
                      }}
                    />
                  </Flex>

                  <Button
                    fontFamily={'NewsReader'}
                    colorScheme="blackAlpha"
                    fontWeight="normal"
                    color="white"
                    bgColor="#A1425C"
                    padding="0 30px"
                    borderRadius="25px"
                    onClick={validatePassword}
                  >
                    Ready to Share?
                  </Button>
                </Box>
                <FormErrorMessage marginTop="4px">{errorMessage}</FormErrorMessage>
              </FormControl>
              <Center>
                <Link href={INVITATO_URL} target="_blank" paddingTop="2rem">
                  <Image
                    src={LOGO_INVITATO}
                    maxWidth="110px"
                    margin="0 0 8px 0"
                    filter="invert(100%)"
                  />
                </Link>
              </Center>
            </>
          </Box>
        </Box>
        {isDesktopView && (
          <Box position="static" height="35px" bgColor="#F0EDE6" margin="auto" marginTop="-35px">
            <Center>
              <Text color="#1A202C" fontSize="13px" marginTop="8px" fontFamily="Newsreader">
                © 2023 Invitato Studio | All Rights Reserved
              </Text>
            </Center>
          </Box>
        )}
      </Box>
    </Box>
  );
}

Auth.propTypes = {
  children: node.isRequired,
};

export default Auth;
